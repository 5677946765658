// src/components/SendData.js
import React, { useState } from 'react';
import { collection, doc, setDoc } from "firebase/firestore";
import { db } from '../firebase';

function SendData() {
  const [arrayData, setArrayData] = useState([]);

  const handleSendData = async () => {
    try {
      await setDoc(doc(collection(db, "arrays"), "arrayData"), {
        array: arrayData
      });
      console.log("Data successfully written!");
    } catch (error) {
      console.error("Error writing document: ", error);
    }
  };

  return (
    <div>
      <button onClick={handleSendData}>Send Data</button>
    </div>
  );
}

export default SendData;
