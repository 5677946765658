import React from "react";
import { AirplaneInFlight} from "phosphor-react";
import "./thankYou.css";
export const ThankYou = () =>
    {
        return(
            <div className="ThankYou">
                <div className="topPortion">
                    <div className="waitingLogo"><AirplaneInFlight size={32}></AirplaneInFlight></div>
                </div>
                <div className="bottomPortion">
                    <a>Please wait a moment</a>
                </div>
            </div>
        );
    }