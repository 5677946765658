
/*
import React, { createContext } from "react";
import { Translate } from "phosphor-react";
import "./TitleBar.css";
import { LanguageProvider } from "../../context/LanguageProvider";
//import { LanguageContext } from "../../context/LanguageContext";

export const TitleBar =() =>
    {
        
       const {toggleLanguage} = createContext(LanguageProvider);
        return(
            <div className="titleBar">
               <div className="logoPort">
                    <div className="logo"></div>
               </div>
               <div className="translate"><Translate size={32} onClick={toggleLanguage}/></div>
            </div>
        );
    }

*/

import React, { createContext } from "react";
import { Link } from "react-router-dom";
import "./TitleBar.css";

export const TitleBar =() =>
    {
        
        return(
            <div className="titleBar">
               <div className="logoPort">
               <Link to="/">
                  <div className='logo'>
                  </div>
              </Link>
               </div>
               <div className="TitlePort">
                    <a>バルサ</a>
               </div>
               <div className="emptySpace">
                    <div className="waitingTime">
                        <p>Waiting Time: 30 minutes</p>
                        
                    </div>
               </div>
            </div>
        );
    }