import React, { createContext, useState, useEffect } from 'react';
import { PRODUCTS } from '../assets/products/products';

export const ShopContext = createContext();

export const ShopContextProvider = (props) => {
  const [cartItems, setCartItems] = useState({});
  const [orderData, setOrderData] = useState(null); // New state for order data

  useEffect(() => {
    const initialCart = {};
    PRODUCTS.forEach((product) => {
      initialCart[product.id] = 0;
    });
    setCartItems(initialCart);
  }, []);

  const addToCart = (itemId) => {
    setCartItems((prev) => ({
      ...prev,
      [itemId]: prev[itemId] + 1,
    }));
  };

  const removeFromCart = (itemId) => {
    setCartItems((prev) => ({
      ...prev,
      [itemId]: prev[itemId] - 1,
    }));
  };

  const getTotalCartAmount = () => {
    let total = 0;
    for (const item in cartItems) {
      if (cartItems[item] > 0) {
        const itemInfo = PRODUCTS.find((product) => product.id === Number(item));
        total += cartItems[item] * itemInfo.price;
      }
    }
    return total;
  };

  const checkout = () => {
    setCartItems({});
  };

  return (
    <ShopContext.Provider
      value={{
        cartItems,
        addToCart,
        removeFromCart,
        getTotalCartAmount,
        checkout,
        setOrderData, // Provide the method to set order data
        orderData, // Provide the order data
      }}
    >
      {props.children}
    </ShopContext.Provider>
  );
};
