import product1 from "./IB_bento.jpg";
import product2 from "./Pizza_naan copy.jpg";
import product3 from "./IB_bento.jpg";
import product4 from "./Pizza_naan copy.jpg";
import product5 from "./IB_bento.jpg"
import product6 from "./Veg_curry.jpg";
import product7 from "./Pizza_naan copy.jpg";
import product8 from "./IB_bento.jpg";

export const PRODUCTS = [
  {
    id: 1,
    productName: "Bento 01",
    itemDes:"The page will reload when you make changes. You may also see any lint errors in the console",
    price: 999.0,
    productImage: product1,
  },
  {
    id: 2,
    productName: "Bento 02",
    itemDes:"The page will reload when you make changes. You may also see any lint errors in the console",
    price: 500,
    productImage: product2,
  },
  {
    id: 3,
    productName: "Bento 03",
    itemDes:"The page will reload when you make changes. You may also see any lint errors in the console",
    price: 699.0,
    productImage: product3,
  },
  {
    id: 4,
    productName: "Bento 04",
    itemDes:"The page will reload when you make changes. You may also see any lint errors in the console",
    price: 700,
    productImage: product4,
  },
  {
    id: 5,
    productName: "Bento 05",
    itemDes:"The page will reload when you make changes. You may also see any lint errors in the console",
    price: 800,
    productImage: product5,
  },
  {
    id: 6,
    productName: "Bento 06",
    itemDes:"The page will reload when you make changes. You may also see any lint errors in the console",
    price: 500,
    productImage: product6,
  },
  {
    id: 7,
    productName: "Bento 07",
    itemDes:"The page will reload when you make changes. You may also see any lint errors in the console",
    price: 900,
    productImage: product7,
  },
  {
    id: 8,
    productName: "Bento 08",
    itemDes:"The page will reload when you make changes. You may also see any lint errors in the console",
    price: 1200,
    productImage: product8,
  },
];
