// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyC3ArSytSbjO6wL_mxTrNcGNQn0WdNmuO8",
  authDomain: "webappdatabase-6857a.firebaseapp.com",
  projectId: "webappdatabase-6857a",
  storageBucket: "webappdatabase-6857a.appspot.com",
  messagingSenderId: "159000905169",
  appId: "1:159000905169:web:31317cf7be9671717d0cf0",
  measurementId: "G-1MV2T9ZCSW"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

const db = getFirestore(app);

export { db };