import React from "react";
import { Link } from "react-router-dom";
import { ShoppingCart,Phone, House } from "phosphor-react";
import "./navbar.css";


export const Navbar = () => {
  return (
    <div className="navbar">
        <Link to="/contact" className="phone"><Phone size={32} /></Link>
        <Link to="/" className="home"><House size={32}/></Link>
        <Link to="/cart" className="cart"><ShoppingCart size={32} /></Link>
    </div>
  );
};
