/*

import React, { useContext } from "react";
import { ShopContext } from "../../context/shop-context";
import { PRODUCTS } from "../../assets/products/products";
import { CartItem } from "./cart-item";
import { useNavigate,Link } from "react-router-dom";
import { collection, addDoc } from "firebase/firestore";
import { db } from "../../firebase"; // Adjust path as necessary
import "./cart.css";

export const Cart = () => {
  const { cartItems, getTotalCartAmount, checkout, setOrderData } = useContext(ShopContext);
  const totalAmount = getTotalCartAmount();
  const navigate = useNavigate();

  const [formData, setFormData] = React.useState({
    fullName: '',
    address: '',
    phoneNumber: '',
    message: ''
  });

  const generateOrderID = () => {
    const now = new Date();
    const year = now.getFullYear().toString().slice(2);
    const month = (now.getMonth() + 1).toString().padStart(2, '0');
    const day = now.getDate().toString().padStart(2, '0');
    const hours = now.getHours().toString().padStart(2, '0');
    const minutes = now.getMinutes().toString().padStart(2, '0');
    const seconds = now.getSeconds().toString().padStart(2, '0');
    return `${year}${month}${day}-${hours}${minutes}${seconds}`;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    const orderID = generateOrderID();
    const orderData = {
      orderID,
      ...formData,
      cartItems: Object.keys(cartItems).map(itemId => {
        if (cartItems[itemId] !== 0) {
          const product = PRODUCTS.find(p => p.id === parseInt(itemId));
          return {
            productName: product.productName,
            quantity: cartItems[itemId],
            price: product.price
          };
        }
        return null;
      }).filter(item => item !== null)
    };
  
    console.log('Order Data:', orderData); // Debug log
    setOrderData(orderData); // Set the order data in context
  
    try {
      // Send order data to Firestore
      const docRef = await addDoc(collection(db, 'orders'), orderData);
      console.log("Document written with ID: ", docRef.id);
      navigate('./send'); // Navigate to the Send component
    } catch (e) {
      console.error("Error adding document: ", e);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  return (
    <>
      <div className="the_page">
        <div className="cartPage">
          <div className="cart">
            {totalAmount > 0 ? (
              <div>
                <h1>Your Cart Items</h1>
              </div>
            ) : (
              <h1 className="emptyCart">Your Shopping Cart is Empty</h1>
            )}

            {PRODUCTS.map((product) => {
              if (cartItems[product.id] !== 0) {
                return <CartItem key={product.id} data={product} />;
              }
              return null;
            })}
          </div>

          {totalAmount > 0 ? (
            <div className="checkout">
              <p className="total">Subtotal: {totalAmount}円</p>
              <button className="continueShopping" onClick={() => navigate("/")}>Continue Shopping</button>
            </div>
          ) : null}
        </div>

        {totalAmount > 0 && (
          <div className='checkoutComp'>
            <h2>Checkout</h2>
            <form className='dataInput' onSubmit={handleSubmit}>
              <label>
                Full Name:
                <input
                  type="text"
                  name="fullName"
                  value={formData.fullName}
                  onChange={handleChange}
                  required
                />
              </label>
              <label>
                Address:
                <input
                  type="text"
                  name="address"
                  value={formData.address}
                  onChange={handleChange}
                  required
                />
              </label>
              <label>
                Phone Number:
                <input
                  type="text"
                  name="phoneNumber"
                  value={formData.phoneNumber}
                  onChange={handleChange}
                  required
                />
              </label>
              <label>
                Message:
                <input
                  type="text"
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                  required
                />
              </label>
              
                  <Link to="/thankyou" className='submitButton' type="submit">
                    Place Order
                  </Link>
              
            </form>
          </div>
        )}
      </div>
    </>
  );
};
*/



import React, { useContext } from "react";
import { ShopContext } from "../../context/shop-context";
import { PRODUCTS } from "../../assets/products/products";
import { CartItem } from "./cart-item";
import { useNavigate } from "react-router-dom";
import { collection, addDoc } from "firebase/firestore";
import { db } from "../../firebase"; // Adjust path as necessary
import "./cart.css";

export const Cart = () => {
  const { cartItems, getTotalCartAmount, checkout, setOrderData } = useContext(ShopContext);
  const totalAmount = getTotalCartAmount();
  const navigate = useNavigate();

  const [formData, setFormData] = React.useState({
    fullName: '',
    address: '',
    phoneNumber: '',
    message: ''
  });

  const generateOrderID = () => {
    const now = new Date();
    const year = now.getFullYear().toString().slice(2);
    const month = (now.getMonth() + 1).toString().padStart(2, '0');
    const day = now.getDate().toString().padStart(2, '0');
    const hours = now.getHours().toString().padStart(2, '0');
    const minutes = now.getMinutes().toString().padStart(2, '0');
    const seconds = now.getSeconds().toString().padStart(2, '0');
    return `${year}${month}${day}-${hours}${minutes}${seconds}`;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const orderID = generateOrderID();
    const orderData = {
      orderID,
      ...formData,
      cartItems: Object.keys(cartItems).map(itemId => {
        if (cartItems[itemId] !== 0) {
          const product = PRODUCTS.find(p => p.id === parseInt(itemId));
          return {
            productName: product.productName,
            quantity: cartItems[itemId],
            price: product.price
          };
        }
        return null;
      }).filter(item => item !== null)
    };

    console.log('Order Data:', orderData); // Debug log
    setOrderData(orderData); // Set the order data in context

    try {
      // Send order data to Firestore
      const docRef = await addDoc(collection(db, 'orders'), orderData);
      console.log("Document written with ID: ", docRef.id);
      navigate('/thankyou'); // Navigate to the Thank You page
    } catch (e) {
      console.error("Error adding document: ", e);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  return (
    <>
      <div className="the_page">
        <div className="cartPage">
          <div className="cart">
            {totalAmount > 0 ? (
              <div>
                <h1>Your Cart Items</h1>
              </div>
            ) : (
              <h1 className="emptyCart">Your Shopping Cart is Empty</h1>
            )}

            {PRODUCTS.map((product) => {
              if (cartItems[product.id] !== 0) {
                return <CartItem key={product.id} data={product} />;
              }
              return null;
            })}
          </div>

          {totalAmount > 0 ? (
            <div className="checkout">
              <p className="total">Subtotal: {totalAmount}円</p>
              <button className="continueShopping" onClick={() => navigate("/")}>Continue Shopping</button>
            </div>
          ) : null}
        </div>

        {totalAmount > 0 && (
          <div className='checkoutComp'>
            <h2>Checkout</h2>
            <form className='dataInput' onSubmit={handleSubmit}>
              <label>
                Full Name:
                <input
                  type="text"
                  name="fullName"
                  value={formData.fullName}
                  onChange={handleChange}
                  required
                />
              </label>
              <label>
                Address:
                <input
                  type="text"
                  name="address"
                  value={formData.address}
                  onChange={handleChange}
                  required
                />
              </label>
              <label>
                Phone Number:
                <input
                  type="text"
                  name="phoneNumber"
                  value={formData.phoneNumber}
                  onChange={handleChange}
                  required
                />
              </label>
              <label>
                Message:
                <input
                  type="text"
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                  required
                />
              </label>
              <button className='submitButton' type="submit">
                Place Order
              </button>
            </form>
          </div>
        )}
      </div>
    </>
  );
};
