
import React, { useContext } from "react";
import { ShopContext } from "../../context/shop-context";


export const CartItem = (props) => {
  const { id, productName, price, productImage } = props.data;
  const { cartItems, addToCart, removeFromCart, updateCartItemCount } =
    useContext(ShopContext);

  return (
    <>
    <div className="cartItem">
      <img src={productImage} />
      <div className="description">
        <p>
          <b>{productName}</b>
        </p>
        <p> Price: {price}円</p>
        <div className="countHandler">
          <button onClick={() => removeFromCart(id)}> - </button>
          <input
            value={cartItems[id]}
            onChange={(e) => updateCartItemCount(Number(e.target.value), id)}
          />
          <button onClick={() => addToCart(id)}> + </button>
        </div>
      </div>
    </div>
    </>
  );
};


/*

import React, { useContext } from "react";
import { ShopContext } from "../../context/shop-context";
import { LanguageContext } from "../../context/LanguageProvider";

export const CartItem = (props) => {
  const { id, productName, price, itemDes, productImage } = props.data;
  const { addToCart, cartItems } = useContext(ShopContext);

  const cartItemCount = cartItems[id];
  const { language } = useContext(LanguageContext);

  return (
    <div className="product">
      <img src={productImage} alt={productName[language]} />
      <div className="description">
        <div className="productName">
          <b>{productName[language]}</b>
        </div>
        <div className="itemDes">
          <p>{itemDes[language]}</p>
        </div>
        <b>{price}円</b>
        <button className="addToCartBttn" onClick={() => addToCart(id)}>
          Add To Cart {cartItemCount > 0 && <> ({cartItemCount})</>}
        </button>
      </div>
    </div>
  );
}
  */