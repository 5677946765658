import "./App.css";
import { BrowserRouter as Router, Routes, Route, redirect } from "react-router-dom";


import { Navbar } from "./components/NavBar02/navbar";    //bottom bar
import { TitleBar } from "./components/NavBar01/TitleBar"; //top bar
import { Shop } from "./pages/shop/shop"; //Shopping page
import { Contact } from "./pages/contact/contact";  //Contact page
import { Cart } from "./pages/cart/cart"; //Cart page
import SendData from "./components/SendData"; //sends compact array packets to the firebase
import { ThankYou } from "./pages/ThankYouPage/thankYou";

import OrderList from "./pages/orderList";

import { ShopContextProvider } from "./context/shop-context";

function App() {

  return (
   
      <ShopContextProvider>    
          <div className="App">
              <Router>
            
                <Navbar />
                <TitleBar />
                <div className="groundContent">
                <Routes>
                  <Route path="/" element={<Shop />} />
                  <Route path="/contact" element={<Contact />} />
                  <Route path="/cart" element={<Cart />} />
                  <Route path="/send" element={<SendData />} /> 
                  <Route path="/orders" element={<OrderList />} />
                  <Route path="/thankYou" element={<ThankYou />}/> 
                </Routes>
                </div>
      
              </Router>
          </div>
        
      </ShopContextProvider>
   
  );
}

export default App;
