import React from "react";
import "./contact.css";

export const Contact = () => {
  const phoneNumber = "0434458335"; // Replace with the desired phone number

  const handleCallClick = () => {
    window.location.href = `tel:${phoneNumber}`;
  };

  return (
    <div className="page">
      <div className="contact">Contact us: </div>
      <button className="call-button" onClick={handleCallClick}>
        Call Us
      </button>
      <a>
      08068692511
      </a>
    </div>
  );
};
